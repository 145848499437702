.shadow {
  border-radius: 100%;
  width: 10rem;
  height: 10rem;
  position: fixed;
  background-color: rgba(0, 122, 204, 0.127);
  -webkit-box-shadow: 0px 0px 1000px 200px rgba(0, 122, 204, 0.1176470588);
  -moz-box-shadow: 0px 0px 1000px 200px rgba(0, 122, 204, 0.1176470588);
  box-shadow: 0px 0px 1000px 200px rgba(0, 122, 204, 0.1176470588);
}
