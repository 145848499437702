@import "@/style/index.scss";

.cta {
  width: 90%;
  margin: 0 auto;
  @include d-flex-column;
  gap: 5vh;
  align-items: flex-start;
  @media screen and (min-width: $desktop) {
    margin-left: 5vw;
    margin-top: 5vh;
  }
}
