@import "@/style/index.scss";

.aboutSectionWrapper {
  @include d-flex;
  &__content {
    @include small-headers;
    font-size: 1.3rem;
    @media screen and (min-width: $large-desktop) {
      font-size: 1.5rem;
    }
  }
}
