@import "@/style/index.scss";

.imageSide {
  position: relative;
  width: 100%;
  height: 100vh;
  &__mobileBackgroundItemImg {
    position: absolute;
    left: 0;
    z-index: 0;
    @media screen and (min-width: $small-desktop) {
      display: none;
    }
  }
  &__desktopBackgroundItemImg {
    display: none;
    @media screen and (min-width: $small-desktop) {
      display: block;
      position: absolute;
      right: 0;
      bottom: 5rem;
    }
  }
  &__ebookImg {
    position: absolute;
    z-index: 1;
    left: 10%;
    top: 5rem;
    @media screen and (min-width: $small-desktop) {
      top: 0;
      left: 1rem;
      width: 467px;
      height: 711px;
    }
  }
}
