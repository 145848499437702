@import "@/style/index.scss";

.container {
  background-color: #fff;
  @include d-flex-column;
  gap: 5vh;
  align-items: flex-start;
  justify-content: flex-start;
  padding-inline: 5%;
  padding-block: 15%;
}
