@import "@/style/index.scss";

.amount {
  font-weight: 700;
  font-size: 3rem;
  letter-spacing: 2px;
  cursor: pointer;
  @media screen and (min-width: $big-tablet) {
    font-size: 4rem;
  }
}
