@import "@/style/index.scss";

.headerWrapper {
  width: 100%;
  &__header {
    @include headerFont();
    font-weight: 700;
    font-size: 2.5rem;
  }
}
