@import "@/style/index.scss";

.opinionWrapper {
  @include d-flex;
  align-items: flex-end;
  gap: 2vh;
  &__note {
    position: relative;
    top: 0.2rem;
    font-weight: 500;
    font-size: 2rem;
  }
  &__starsWrapper {
    @include d-flex;
  }
}
