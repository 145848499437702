@import "@/style/index.scss";

.aboutMeWrapper {
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $border-med;
  width: 90%;
  padding: $big-padding;
  padding-bottom: 3rem;
  @include d-flex-column;
  align-items: flex-start;
  gap: 2vh;
  @media screen and (min-width: $tablet) {
    width: 40rem;
  }
  @media screen and (min-width: $desktop) {
    height: 52rem;
    justify-content: space-between;
  }
}
